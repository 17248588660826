import React from "react";
import { graphql } from "gatsby";
import { useIntl } from "react-intl";
import Img from "gatsby-image";
import { BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import Layout from "../components/layout";
import Seo from "../components/seo";

const AccountTemplate = ({ data }) => {
  const intl = useIntl();

  const {
    title,
    main_title,
    subtitle,
    section_1_text,
    section_1_image,
    section_2_text,
    section_3_text,
    section_3_image,
  } = data.contentfulEspaceClient;

  const renderOptions = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="h2-editorial">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className="h3-editorial">{children}</h3>
      ),
    },
    renderText: (text) => {
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };

  return (
    <Layout>
      <Seo title={title} description={main_title} />
      <article>
        <section className="section section-white">
          <div className="introduction introduction-clouds">
            <div className="introduction-container">
              <div className="introduction-inner">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-xl-10">
                      <p className="eyebrow">
                        {title} –{" "}
                        {intl.formatMessage({ id: "simplement-bien" })}
                      </p>
                      <h1>{main_title}</h1>
                      {subtitle && <p className="lead">{subtitle}</p>}
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section section-white">
          <div className="container">
            <div className="columns">
              <div className="columns-item columns-item-image">
                <Img
                  fluid={{
                    ...section_1_image.fluid,
                    aspectRatio: 460 / 340,
                  }}
                />
              </div>
              <div className="columns-item columns-item-text">
                {renderRichText(section_1_text, renderOptions)}
              </div>
            </div>
          </div>
        </section>
        <section className="section section-white text-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6">
                {renderRichText(section_2_text, renderOptions)}
                <div className="d-flex flex-column align-items-center mt-10">
                  <a
                    className="button mb-4"
                    href={`https://www.secure-direct-hotel-booking.com/customer/lQM8UpTFUCbP5--hRVW3Xu3ZuB8ofe0xLTqy5g9-QSs@/${intl.locale.toUpperCase()}/login`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {intl.formatMessage({ id: "account.page.sign-up" })}
                  </a>
                  <a
                    href={`https://www.secure-direct-hotel-booking.com/customer/lQM8UpTFUCbP5--hRVW3Xu3ZuB8ofe0xLTqy5g9-QSs@/${intl.locale.toUpperCase()}/login`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {intl.formatMessage({ id: "account.page.sign-in" })}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section section-white">
          <div className="container">
            <div className="columns columns-reverse">
              <div className="columns-item columns-item-image">
                <Img
                  fluid={{
                    ...section_3_image.fluid,
                    aspectRatio: 460 / 340,
                  }}
                />
              </div>
              <div className="columns-item columns-item-text">
                {renderRichText(section_3_text, renderOptions)}
              </div>
            </div>
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default AccountTemplate;

export const query = graphql`
  query ($id: String!, $slug: String!, $locale: String!) {
    allSitePage(filter: { context: { id: { eq: $id } } }) {
      nodes {
        path
        context {
          locale
        }
      }
    }
    allContentfulPage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        contentful_id
        title
        slug
        category
      }
    }
    contentfulHotel(
      contentful_id: { eq: "7Ghq3MGGxup1gZhcPpUnH" }
      node_locale: { eq: $locale }
    ) {
      meta_description
      hero_image {
        file {
          url
        }
      }
    }

    contentfulEspaceClient(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      title
      main_title
      subtitle
      section_1_text {
        raw
      }
      section_1_image {
        fluid(maxWidth: 460, quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      section_2_text {
        raw
      }
      section_3_text {
        raw
      }
      section_3_image {
        fluid(maxWidth: 460, quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`;
